import * as React from "react"
import SideBarLayout from "../../components/SideBarLayout"
import { getLegalPages } from "../../api/content"
import ReactMarkdown from "react-markdown"

const SettingsLegalPage = () => {
  const [legal, setLegal] = React.useState()

  React.useEffect(() => {
    getLegalPages().then(response => {
      if (response.ok) {
        response.json().then(json => {
          setLegal(json)
        })
      }
    })
  })
  return (
    <SideBarLayout>
      <h1 className="mt-16 md:mt-0">Legales</h1>
      <>
        {legal ? (
          <ReactMarkdown className="p-8 md:mx-14">
            {legal.terminosYCondiciones}
          </ReactMarkdown>
        ) : null}
      </>
    </SideBarLayout>
  )
}

export default SettingsLegalPage
